// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-guests-js": () => import("./../../../src/pages/guests.js" /* webpackChunkName: "component---src-pages-guests-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-songs-js": () => import("./../../../src/pages/songs.js" /* webpackChunkName: "component---src-pages-songs-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-venues-js": () => import("./../../../src/pages/venues.js" /* webpackChunkName: "component---src-pages-venues-js" */),
  "component---src-templates-show-embed-js": () => import("./../../../src/templates/show-embed.js" /* webpackChunkName: "component---src-templates-show-embed-js" */),
  "component---src-templates-show-js": () => import("./../../../src/templates/show.js" /* webpackChunkName: "component---src-templates-show-js" */),
  "component---src-templates-song-js": () => import("./../../../src/templates/song.js" /* webpackChunkName: "component---src-templates-song-js" */),
  "component---src-templates-venue-js": () => import("./../../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */)
}

